<template>
  <div style="color: #000" class="box my_warp">
    <h2 style="text-align: center; font-size: 17px;padding:15px 0">
      {{COMPANY_NAME_All}}直播儿童个人信息保护指引
    </h2>
    &emsp;&emsp;{{COMPANY_NAME_All}}及其关联公司（以下简称“我们”）深知儿童（指未满十四周岁的未成年人，下同）个人信息和隐私安全的重要性。本《{{COMPANY_NAME_All}}直播儿童个人信息保护指引》（以下简称“本指引”），旨在向监护人（以下简称“您”）和您监护的儿童（指未满十四周岁的未成年人，以下简称“您的孩子”）说明我们如何收集、使用、存储和处理儿童个人信息，以及我们为您与儿童提供的访问、更正、删除、保护这些信息的方式。在使用我们的各项产品和服务（以下统称“{{COMPANY_NAME_All}}直播服务”）前，请您仔细阅读并充分理解本指引，特别是以粗体/下划线标识的条款应重点阅读，在确认充分理解并同意全部条款后再开始使用或允许您的孩子使用。<br>
    &emsp;&emsp;监护人须知：<br>
    &emsp;&emsp;儿童个人信息保护需要您和我们的携手共同努力。我们希望您协助我们保护您的孩子的个人信息，请您确保您的孩子在您的同意和指导下使用我们的产品或服务和向我们提交个人信息（如需）。我们衷心提示您教育引导儿童增强个人信息保护意识和能力，维护儿童个人信息安全。<br>
    &emsp;&emsp;您点击同意使用或继续使用我们提供的产品与/或服务的，表示您同意我们按照《<el-link type="primary"
      @click="gotoConceal">{{COMPANY_NAME_All}}直播隐私政策</el-link>》及本指引的约定收集、使用和处理您和您监护的儿童的个人信息。如果您不同意本指引的内容，请您要求您的孩子立即停止访问或使用我们的产品与/或服务。<br>
    &emsp;&emsp;儿童提醒：<br>
    我们建议任何儿童参加网上活动都应事先取得监护人的同意。如果您是儿童，请通知您的监护人阅读本指引，并在您的监护人授权同意后使用我们的产品或服务。如果您的监护人不同意本规则或不同意您使用我们的产品与/或服务的，请您停止使用我们的产品与/或服务。
    本指引适用于您与/或您监护的儿童在使用我们的产品与/或服务的过程中，我们收集、使用、储存和处理儿童个人信息等活动。若我们获知您的孩子是未满14周岁的未成年人，我们将根据本规则采取特殊保护措施保护我们获得的您的孩子的个人信息。<br>
    &emsp;&emsp;《<el-link type="primary" @click="gotoConceal">{{COMPANY_NAME_All}}直播隐私政策</el-link>》说明了我们收集、使用和处理个人信息的一般做法，本规则为《<el-link
      type="primary" @click="gotoConceal">{{COMPANY_NAME_All}}直播隐私政策</el-link>》的一部分。如在儿童个人信息保护规则层面，本指引与《<el-link type="primary"
      @click="gotoConceal">{{COMPANY_NAME_All}}直播隐私政策</el-link>》正文存在不一致的，本规则优先使用。本指引未尽事宜，则参照适用《<el-link type="primary"
      @click="gotoConceal">{{COMPANY_NAME_All}}直播隐私政策</el-link>》或具体实际产品隐私政策。除非本指引另有说明，本指引中涉及的词语含义与《<el-link type="primary"
      @click="gotoConceal">{{COMPANY_NAME_All}}直播隐私政策</el-link>》
    正文中的一致。<br>
    &emsp;&emsp;请您理解，绝大多数情形下我们无法识别使用我们的产品与/或服务的具体人员信息是否属于儿童个人信息，即我们无法判断收集和处理的设备信息、上网操作记录等不具备明显年龄识别特征的个人信息是否属于儿童个人信息，我们将按照《<el-link
      type="primary" @click="gotoConceal">{{COMPANY_NAME_All}}直播隐私政策</el-link>》及具体产品隐私政策（如有）收集和处理上述用户的个人信息。
    本指引将帮助您了解以下内容： <br>
    &emsp;&emsp;一、我们如何收集和使用儿童个人信息<br>
    &emsp;&emsp;二、我们如何共享、转让或公开披露儿童个人信息<br>
    &emsp;&emsp;三、我们如何存储和保护儿童个人信息
    &emsp;&emsp;四、您或/与儿童如何管理儿童个人信息<br>
    &emsp;&emsp;五、如何联系我们<br>
    如您在阅读本指引或您和您监护的儿童在使用我们的产品与/或服务的过程中有任何疑惑或其他相关事宜的，请通过文末的方式与我们联系，我们将在十五个工作日内进行身份核验和处理。<br>
    一、我们如何收集和使用儿童个人信息<br>
    &emsp;&emsp;我们会严格履行法律法规规定的儿童个人信息保护义务与责任，遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，不会收集与我们提供的产品或服务无关的儿童个人信息。我们可能收集您的孩子的个人信息的具体业务功能场景包括：<br>
    &emsp;&emsp;1.在部分单项服务或产品的实名认证环节，我们可能会要求用户填写个人生日信息或根据用户填写的身份信息，识别该用户是否为儿童，并按照法律法规的规定，对相关儿童个人信息进行对应的合规保护。请您理解，此处所说的“要求用户填写个人生日信息或根据用户填写的身份信息”是指具备法律意义的实名认证操作，一般是由您提交身份证明文件并填写有关信息；产品或服务中出于社交娱乐意义的个人生日信息填写并非实名认证环节，也不具备法律意义。<br>
    &emsp;&emsp;2.当我们识别出用户为儿童时，我们可能会收集您的联系方式（例如手机号码、电子邮箱），并联系您以验证监护关系。为了更好地保护儿童的权益，我们还可能向您收集更多信息（例如姓名、身份证、户口本或其他监护关系证明），以便进一步验证监护关系。<br>
    &emsp;&emsp;3.我们严格遵守法律法规的规定和与用户的约定，将收集的您和您所监护的儿童的信息用于以下用途：<br>
    &emsp;&emsp;（1）验证儿童和监护人身份；<br>
    &emsp;&emsp;（2）用于优化产品或服务、安全保障、服务调查、处理与未成年人相关的投诉。<br>
    &emsp;&emsp;请您注意，如我们提供的产品或服务涉及到上传头像和昵称等功能，我们强烈建议您指导儿童在使用我们产品或服务的过程中不要上传真实的本人肖像照片或使用本人真实姓名等信息。<br>
    &emsp;&emsp;4.实现青少年模式功能。您或儿童可以在我们的产品与/或服务中开启青少年模式。在青少年模式下，我们仅会收集设备信息（包括设备MAC地址、唯一设备识别码IMEI、登录IP地址、设备型号、设备名称、设备标识、浏览器类型和设置、语言设置、操作系统和应用程序版本、网络信息、产品版本号）、上网操作记录（包括播放记录、播放时长、访问日期和时间、视频浏览记录、点击记录）等实现最基础功能所必需的信息，我们收集这些信息是为了保障您的账号安全和青少年模式防破解等目的，以便正常实现青少年模式相关功能（包括时间锁、内容过滤等）。
    在儿童使用{{COMPANY_NAME_All}}直播产品和服务过程中，我们可能收集和使用的儿童个人信息，具体请查阅《<el-link type="primary"
      @click="gotoConceal">{{COMPANY_NAME_All}}直播隐私政策</el-link>》“我们如何收集和使用您的信息”章节进行详细了解。如我们需要超出上述范围收集和使用儿童个人信息，我们将再次征得您的同意。<br>
    二、我们如何共享、转让或公开披露儿童个人信息 <br>
    （一）共享<br>
    &emsp;&emsp;除以下情形外，未经监护人的同意，我们不会与任何第三方共享儿童个人信息：<br>
    &emsp;&emsp;（1）与作为监护人的您之间的共享； <br>
    &emsp;&emsp;（2）事先已征得您的明确授权同意；<br>
    &emsp;&emsp;（3）为了必要/合理的业务的顺利开展、满足您和儿童的要求、履行我们在相关用户协议或本规则中的义务和行使我们的权利或遵守法律规定等目的，与为我们的产品与/或服务提供功能支持的服务提供商的必要共享，但我们承诺我们只会共享儿童必要个人信息，如果我们需要改变儿童个人信息的处理目的时，将再次征求您的授权同意。请您放心，我们会对服务商和其服务行为等进行安全评估，签署协议，明确责任、处理事项、处理期限、处理性质和目的等，要求其处理行为不得超出我们的授权范围；且请您放心，除得到您的授权同意，否则我们不会将您监护的儿童的个人信息与第三方共享用于营销活动。<br>
    &emsp;&emsp;（4）与{{COMPANY_NAME_All}}直播关联应用内部必要的、合理的共享。为便于我们基于{{COMPANY_NAME_All}}直播统一的账号体系向您与/或您的孩子提供一致化服务、识别账号异常、保护{{COMPANY_NAME_All}}直播或用户或公众的人身财产安全免遭侵害以及便于您进行统一管理，您知悉并同意，您与/或您的孩子的相关个人信息可能会在{{COMPANY_NAME_All}}直播关联的产品内部进行必要共享；<br>
    &emsp;&emsp;（5）实现本指引第一部分“我们如何收集和使用儿童个人信息“所需；<br>
    &emsp;&emsp;（6）与（可能的）诉讼、仲裁、审判、执行等法律程序直接相关的；<br>
    &emsp;&emsp;（7）有权机关的要求、法律法规等规定的其他情形。 <br>
    （二）转让 <br>
    &emsp;&emsp;1.随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而儿童的个人信息有可能作为其中的一部分而被转移。我们会进行安全评估，并要求新的持有儿童个人信息的公司、组织继续受本指引的约束，否则，我们将要求该公司、组织重新向监护人征求授权同意。<br>
    &emsp;&emsp;2. 在获得您明确同意后，我们会向第三方转让儿童个人信息。<br>
    &emsp;&emsp;3.在您主动要求的情况下，我们会向第三方转移儿童个人信息。<br>
    （三）公开披露<br>
    &emsp;&emsp;一般情况下，我们禁止对外披露儿童个人信息，但我们可能基于以下目的披露儿童的个人信息：<br>
    &emsp;&emsp;1.在获取您同意的情况下，公开披露儿童个人信息；<br>
    &emsp;&emsp;2.根据法律法规、行政或司法要求所必须提供儿童信息的情况下，我们可能会依据所要求的信息类型和披露方式披露儿童的个人信息。<br>
    三、我们如何存储和保护儿童个人信息<br>
    &emsp;&emsp;（一）我们仅在本指引所述目的所必需期间和法律法规要求的最短时限内储存儿童个人信息。如我们终止服务或运营，我们将及时停止继续收集儿童个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对儿童的个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。<br>
    &emsp;&emsp;（二）我们非常重视儿童的隐私安全，成立了专门的个人信息保护机构，并采取一切合理可行的措施，保护儿童个人信息：<br>
    我们会采取加密等技术措施储存儿童个人信息，确保信息安全。同时，我们对工作人员以最小授权为原则，严格设定信息访问权限，控制儿童个人信息知悉范围；工作人员访问儿童个人信息的，须经过儿童个人信息保护负责人或者其授权的管理人员审批，记录访问情况，并采取技术措施，避免违法复制、下载儿童个人信息。如我们发现儿童个人信息发生或者可能发生泄露、毁损、丢失的，会立即启动应急预案，采取补救措施，并将事件相关情况以邮件、信函、电话、推送通知、公告等方式告知受影响的监护人和儿童。
    如您希望了解更多，请查阅《<el-link type="primary"
      @click="gotoConceal">{{COMPANY_NAME_All}}直播隐私政策</el-link>》“我们如何存储您的个人信息”和“我们如何保护个人信息的安全”章节，详细了解我们如何储存和保护儿童个人信息。
    请您和儿童务必妥善保管好{{COMPANY_NAME_All}}直播帐号、密码及其他身份要素。儿童在使用{{COMPANY_NAME_All}}直播服务时，我们会通过帐号、密码及及其他身份要素来识别其身份。一旦您和儿童泄露了前述信息，可能会蒙受损失，并可能对自身产生不利影响。如您和儿童发现帐号、密码及/或其他身份要素可能或已经泄露时，请立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。<br>
    四、您或/与儿童如何管理儿童个人信息<br>
    &emsp;&emsp;在您或/与儿童用户使用{{COMPANY_NAME_All}}直播产品或服务期间，为了您或/与儿童可以更加便捷地访问、更正、删除相关个人信息，我们在产品设计中为您提供了相应的操作设置，您也可以通过我们文末提供的联系方式，来反馈和咨询相关信息。我们会在核实您反馈的问题后十五个工作日内与您联系。请您理解，在核实过程中，我们可能需要您提供儿童帐号、联系方式等个人信息，以便在完成身份验证和问题核实后继续响应您的需求。<br>
    &emsp;&emsp;（一）访问儿童个人信息<br>
    &emsp;&emsp;您或/与您监护的儿童可以查看儿童用户在使用我们产品或服务中提供或产生的个人信息，包括但不限于用户名、密码、头像、昵称、性别、主动发布的内容等。<br>
    &emsp;&emsp;（二）更正儿童个人信息<br>
    &emsp;&emsp;当您或/与您监护的儿童发现我们收集、使用和处理的儿童个人信息有错误时，可以联系我们进行更正。我们会在完成身份验证和核实问题后，及时采取措施予以更正。<br>
    &emsp;&emsp;（三）删除儿童个人信息<br>
    如存在以下情况，您或/与您监护的儿童可以请求我们删除收集、使用和处理的儿童个人信息。我们会在完成身份验证和核实问题后，及时采取措施予以删除。<br>
    &emsp;&emsp;1、若我们违反法律、行政法规的规定或者双方的约定收集、存储、使用、转移、披露儿童个人信息；<br>
    &emsp;&emsp;2、若我们超出目的范围或者必要期限收集、存储、使用、转移、披露儿童个人信息；<br>
    &emsp;&emsp;3、您撤回同意； 4、您或/与您监护的儿童通过注销等方式终止使用产品或者服务的；<br>
    &emsp;&emsp;5、法律法规等规定的其他情形。<br>
    &emsp;&emsp;但请注意，若您和被监护儿童要求我们删除特定儿童个人信息，可能导致该儿童用户无法继续使用我们的产品和服务或产品和服务中的某些具体业务功能。如您发现在未事先征得您同意的情况下收集了您的儿童的个人信息，请及时联系我们，我们会设法尽快删除相关数据。
    关于其他权利的行使，请查阅《<el-link type="primary" @click="gotoConceal">{{COMPANY_NAME_All}}直播隐私政策</el-link>》“管理您的个人信息”章节，详细了解我们如何保障儿童个人信息权益。<br>
    五、如何联系我们<br>
    &emsp;&emsp;我们设有个人信息保护专职部门并指定了专门的儿童个人信息保护负责人，将严格按照本指引保护儿童个人信息。如您与/或儿童对本指引、{{COMPANY_NAME_All}}直播的儿童个人信息保护规则、措施的相关事宜有任何问题、意见或建议，请随时与我们联系。<br>
    &emsp;&emsp;公司名称：{{COMPANY_NAME_All}} 联系邮箱：xzb@nineton.cn<br>
    &emsp;&emsp;联系人：{{COMPANY_NAME_All}}直播法务合规部<br>
    &emsp;&emsp;我们将尽快审核所涉问题，并在验证您与/或儿童身份后的十五个工作日内回复。
  </div>
</template>

<script>
export default {
  data() {
    return {
      COMPANY_NAME_All:process.env.VUE_APP_COMPANY_NAME_All,
      COMPANY_NAME_ABB:process.env.VUE_APP_COMPANY_NAME_ABB,
    }
  },
  methods: {
    gotoConceal() {
      this.$router.push("/conceal");
    }
  }
};
</script>

<style lang="less" scoped>.box {
  font-size: 12px;
}</style>